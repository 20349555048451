/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import Popup from "./CustomPopup";
import { anim, ScrollReveal } from "../container/ScrollReveal";
import AxelarLogo from "../assets/ast/axelar_logo.png";
import EvmosLogo from "../assets/ast/evmos_logo.png";
import CoinLogo from "../assets/ast/coin.png";
import GravityLogo from "../assets/ast/gravity.jpg";
import CelestiaLogo from "../assets/ast/celestia-logo.png";
import UmeeLogo from "../assets/ast/umee.png";
import NomicLogo from "../assets/ast/nomic.png";
import NolusLogo from "../assets/ast/Nolus.svg";
import MinaLogo from "../assets/ast/mina-wordmark-light.svg";
import ObolIconLight from "../assets/ast/obal-eth.2.svg";
import Boxbg from "../assets/Qubelabs-box.png";
import contentCapy from "../assets/content-copy.png";
import externallink from "../assets/external-link.png";
import questionCircle from "../assets/question-circle.png";
import dymensionLogo from "../assets/ast/dymension.png";



export default function Staking() {
  const [isOpen, setIsOpen] = useState(false);
  const [CartValue, setCartValue] = useState(0);
  const [tooltipText, setTooltipText] = useState(false);
  const textAreaRef = useRef(null);

  const data = [
    {
      title: 'Celestia',
      url: 'https://celestia.org',
      value: '14',
      logo: CelestiaLogo,
      rewardRate: '14%',
      commission: '5%',
      operatorAddress: 'celestiavaloper1j2jq259d3rrc24876gwxg0ksp0lhd8gy49k6st',
      guideLink: 'https://docs.celestia.org/learn/how-to-stake-tia',
      stakeUrl: 'https://wallet.keplr.app/explore?modal=staking&chain=celestia&validator_address=celestiavaloper1j2jq259d3rrc24876gwxg0ksp0lhd8gy49k6st&step_id=2'
    },
    {
      title: 'Axelar Network',
      url: 'https://axelar.network',
      value: '8',
      logo: AxelarLogo,
      rewardRate: '8%',
      commission: '10%',
      operatorAddress: 'axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl',
      guideLink: 'https://medium.com/qubelabs/how-to-stake-axl-token-with-keplr-wallet-ceca6af433eb',
      stakeUrl: 'https://wallet.keplr.app/explore?modal=staking&chain=axelar-dojo-1&validator_address=axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl&step_id=2'

    },
    {
      title: 'Dymension',
      url: 'https://dymension.xyz/',
      value: '35',
      logo: dymensionLogo,
      rewardRate: '35%',
      commission: '5%',
      operatorAddress: 'dymvaloper12qeeprgtt42ujha56pp4kcnv0hpfy0kgvzwqef',
      guideLink: '',
      stakeUrl: 'https://wallet.keplr.app/?modal=staking&chain=dymension_1100-1&validator_address=dymvaloper12qeeprgtt42ujha56pp4kcnv0hpfy0kgvzwqef&step_id=2'

    },
    {
      title: 'Ethereum via Obol',
      url: 'https://obol.tech/',
      value: '8',
      logo: ObolIconLight,
      rewardRate: '8%',
      commission: '5%',
      operatorAddress: '0xb2a4d7c8a5391c52da1d845cfd526d469f247d56f685b46333e203befb93bab0312b3e888bd1a7a6a36acd57af6654dd',
      guideLink: '',
      stakeUrl: 'https://mainnet.beaconcha.in/validator/0x0xb2a4d7c8a5391c52da1d845cfd526d469f247d56f685b46333e203befb93bab0312b3e888bd1a7a6a36acd57af6654dd#deposits'

    },
    {
      title: 'Umee',
      url: 'https://umee.cc',
      value: '14',
      logo: UmeeLogo,
      rewardRate: '14%',
      commission: '10%',
      operatorAddress: 'umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp',
      guideLink: 'https://medium.com/qubelabs/how-to-stake-umee-token-with-keplr-wallet-b7dd287314ae',
      stakeUrl: 'https://wallet.keplr.app/chains/umee?modal=staking&chain=umee-1&validator_address=umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp&step_id=2'
    },
    {
      title: 'Evmos',
      url: 'https://evmos.org',
      value: '10',
      logo: EvmosLogo,
      rewardRate: '10%',
      commission: '5%',
      operatorAddress: 'evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j',
      guideLink: 'https://medium.com/qubelabs/how-to-stake-evmos-with-keplr-wallet-c39dc7b8ad10',
      stakeUrl: 'https://wallet.keplr.app/chains/evmos?modal=staking&chain=evmos_9001-2&validator_address=evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j&step_id=2'
    },
    {
      title: 'Agoric',
      url: 'https://agoric.com',
      value: '8',
      logo: CoinLogo,
      rewardRate: '8%',
      commission: '8%',
      operatorAddress: 'agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt',
      guideLink: 'https://medium.com/qubelabs/how-to-stake-agoric-bld-with-keplr-wallet-34e9250a00f5',
      stakeUrl: 'https://wallet.keplr.app/chains/agoric?modal=staking&chain=agoric-3&validator_address=agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt&step_id=2'
    },
    /* #{
      #title: 'Gravity Bridge',
      #url: 'https://www.gravitybridge.net',
      #value: '10',
      #logo: GravityLogo,
      #rewardRate: '10%',
      #commission: '10%',
      #operatorAddress: 'gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l',
      #guideLink: '/',
      #stakeUrl: 'https://wallet.keplr.app/chains/gravity-bridge?modal=staking&chain=gravity-bridge-3&validator_address=gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l&step_id=2'
    #}, */
    {
      title: 'Nomic',
      url: 'https://nomic.io',
      value: 'N/A',
      logo: NomicLogo,
      rewardRate: 'N/A%',
      commission: '3%',
      operatorAddress: '',
      guideLink: '/',
      stakeUrl: 'https://app.nomic.io/staking#stake'
    },
    {
      title: 'Nolus',
      url: 'https://nolus.io',
      value: '12',
      logo: NolusLogo,
      rewardRate: '12%',
      commission: '5%',
      operatorAddress: 'nolusvaloper1dmgk2jd4epnwtpzah52tp46765s6yy78lcsrqx',
      guideLink: '/',
      stakeUrl: 'https://explorer.nolus.io/pirin-1/staking/nolusvaloper1dmgk2jd4epnwtpzah52tp46765s6yy78lcsrqx',
    },
    {
      title: 'MINA',
      url: 'https://minaprotocol.com',
      value: '10',
      logo: MinaLogo,
      rewardRate: '10%',
      commission: '5%',
      operatorAddress: 'B62qm2eYCip2jy5FFvUuygSCpiPg79ZhWT5S2mr6bCBfGZ4kXtBYUxd',
      guideLink: '',
      stakeUrl: 'https://www.aurowallet.com/',
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsOpen(false);
  };

  const togglePopupStake = (inputValue) => {
    setIsOpen(true);
    setCartValue(inputValue);
  };

  const handleMouseInClick = async (address) => {
    await navigator.clipboard.writeText(address);
    setTooltipText(true);
    setTimeout(() => {
      setTooltipText(false);
    }, 1000);
  };

  return (
    <section id="networks" className="mt-24">
      {isOpen && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            handleClose={() => setIsOpen(false)}
            handleCloseValue={isOpen}
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={data[CartValue]?.logo} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">
                            {data[CartValue]?.title}
                          </h4>

                          <a
                            href={data[CartValue]?.url}
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            {data[CartValue]?.url}
                            <img src={externallink} alt="" />
                          </a>
                        </div>

                      </div>
                    </div>

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0">

                        <div className="card-headnig tooltip">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"y
                            title="Copy to clipboard"
                          >
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  data[CartValue]?.operatorAddress
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                            {tooltipText ? (
                              <span class="tooltiptext"> Address copied </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href={data[CartValue]?.operatorAddress}
                        >
                          {data[CartValue]?.operatorAddress}
                        </p>

                        <a href={data[CartValue]?.guideLink} target="_blank" className=" d-flex   qustion-img" rel="noreferrer" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>

                                <p className="fantom-card1">{data[CartValue]?.rewardRate}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Commission</h3>
                                <p className="fantom-card2">{data[CartValue]?.commission}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                            <a href={data[CartValue]?.stakeUrl} target="_blank" rel="noreferrer">
                              <button
                                type="button"
                                className="steke-model-btn  w-full"

                              >
                                Stake
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a className=" d-flex qustion-img-mobile">
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                              <a href={data[CartValue]?.stakeUrl} target="_blank" rel="noreferrer">
                                <button
                                  type="button"
                                  className="steke-model-btn-mobile "
                                >
                                  Stake
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }

          />
        </div>
      )}


      <div className="container"></div>
      <div className="container-xxl relative pr-7">
        <img
          className="features-transition absolute  -left-4 -top-4 custom-left  w-96 transform"
          src={Boxbg}
          alt=""
        />

        <div className="pt-36">
          <div className="rounded-2xl bg-slate-200 bg-opacity-60 backdrop-blur-sm px-4 pb-4 pt-16 md:p-16">
            <div className="text-center">
              <ScrollReveal config={anim.animate5}>
                <h5 className="text-5xl font-bold text-primary ">
                  Stake with us
                </h5>
              </ScrollReveal>
              <ScrollReveal config={anim.animate6}>
                <p className="text-primary-dark font-medium mt-6 mb-12">
                  Earn rewards for staking your tokens. Here are the
                  networks we validate, support and help grow their communities.
                </p>
              </ScrollReveal>
            </div>

            <div class=" row no-gutters grid xxl:grid-cols-4 lg:grid-cols-2 gap-4">
              {data?.map((item, index) => {
                return (
                  <div class="stake-card-custom col-sm-2 mx-auto my-3" key={index}>
                    <ScrollReveal config={anim.animate8}>
                      <div className="stake-card">
                        <div className="d-flex p-1 no-gutters justify-center">
                          <div className="row justify-center">
                            <img alt={`${item?.title} Logo`} src={item?.logo} className={"img111"} />
                            <div className="">
                              <div className="head-text">{item?.title}</div>
                              <div className="font-bold font-bold-card">{item?.value}%</div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn is-primary w-full mt-2 btn-top"
                          type="button"
                          value="Click to Open Popup"
                          onClick={() => togglePopupStake(index)}
                        >
                          Stake
                        </button>
                      </div>
                    </ScrollReveal>
                  </div>
                )
              })}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
