import Layout from "./Layout";

function App() {
  return (
    <div id="top" className="App font-Montserrat">
      <Layout />
    </div>
  );
}

export default App;
