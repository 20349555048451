import React, {useState} from "react";
import axios from 'axios';
import logo from "../assets/main-logo.svg";
import ScrollReveal, { anim } from "./ScrollReveal";

import swal from 'sweetalert';

export default function Footer() {
  // const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("refresh prevented", e.target[0].value);
    // if(e.target[0].value == ""){
    //   alert("Please fill ")
    // }
    const frmdetails = {
      'email' : e.target[0].value,



  }
    axios.post("https://qubelabs.io/newsletter.php",frmdetails).then(res=>{
      // console.log(res.data)
      swal("Good job!",res.data.message , "success");
      e.target.reset();
    }).catch(error=>{
      swal("Good job!", "There is some problem in request", "error");
    })

  };
  const submitValue = (e) => {
    e.preventDefault();
    // axios.post("",)
    const frmdetails = {
        'name' : lName,
        'email' : email,
        'message':message

    }
    var config = {
      origin: ["http://localhost:3001"],
      allowedHeaders: [
        "Content-Type",
        "Authorization",
        "Access-Control-Allow-Methods",
        "Access-Control-Request-Headers"
      ],
      credentials: true,
      enablePreflight: true
    };
    axios.post("https://qubelabs.io/sendemail.php",frmdetails).then(res=>{
      swal("Good job!", "Thank you for getting in touch with us! Your message has been successfully received, and we will respond as soon as possible.", "success");
      e.target.reset();
    //  console.log(res.data)
    }).catch(error=>{
      swal("Good job!", "There is some problem in request", "error");
    })
    // console.log(frmdetails);
}
//  const emailSubscriptions = (e) => {
//    console.log(e.target.value);
//     e.preventDefault();
//     emailjs.sendForm('service_e655ueI', 'template_sdfn2342g',   e.target, 'user_FS76384TH89394B254B24CB')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//       e.target.reset();
//   }
  return (
    <footer
      id="contact"
      className="container-xxl"
    >
      <div className="bg-slate-200 bg-opacity-60 backdrop-blur-sm container-xxl mt-20 rounded-t-lg px-4 pb-4 pt-16 md:px-14 md:pb-14 md:pt-20 font-medium ">
        <div className="row mb-4">
          <div className="col w-full md:w-6/12">
            <ScrollReveal config={anim.animate5}>
              <h4 className="text-primary text-4xl font-bold">
                
                <div className="pt-1">We'd love to hear from you</div>
              </h4>
            </ScrollReveal>
            <ScrollReveal config={anim.animate8}>
              <p className="text-primary-dark mt-4 font-medium text-sm mb-4 md:mb-0">
              Either fill out the form with your inquiry or contact us
                directly via hello@qubelabs.io and we will respond as early as possible. We look forward to hearing from you!
              </p>
            </ScrollReveal>
          </div>
          <div className="col w-full md:w-6/12">
            <form action="#" onSubmit={submitValue}  data-form-title="CONTACT US" >
              <ScrollReveal config={anim.animate7}>
                <input type="hidden" data-form-email="true"/>
              </ScrollReveal>
              <ScrollReveal config={anim.animate7}>
                <div className="mb-2">
                  <input
                    type="text"
                    className="form-control text-sm"
                    name="name"
                    required
                    placeholder="Name"
                    onChange={e => setlName(e.target.value)}
                  />
                </div>
              </ScrollReveal>
              <ScrollReveal config={anim.animate7}>
                <div className="mb-2">
                  <input
                    type="email"
                    className="form-control text-sm"
                    name="email"
                    required
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate7}>
                <div className="mb-2">
                  <textarea required
                    className="form-control text-sm"
                    name="message"
                    placeholder="Message"
                    rows="4"
                    onChange={e => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </ScrollReveal>
              <ScrollReveal config={anim.animate8}>
                <button className="btn is-primary is-expanded" type='submit'>Send</button>
              </ScrollReveal>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col w-full lg:w-3/12 md:w-3/12">
            <ScrollReveal config={anim.animate5}>
              <a href="#top" className="w-full max-w-[150px] inline-block">
                <img src={logo} alt="logo" />
              </a>
            </ScrollReveal>
            <ScrollReveal config={anim.animate8}>
              <p className="text-xs leading-5">
              Qubelabs is an independent organization of validators, builders, network contributors and community builders.

              </p>
            </ScrollReveal>
          </div>
          <div className="col w-6/12 lg:w-3/12 md:w-3/12">
            <div className="text-sm font-bold md:font-medium mt-6">Navigation</div>
            <ul className="mt-4">
              <li>
                <a
                  className="text-xs text-primary-dark no-underline inline-block hover:text-secondary"
                  href="#top"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="text-xs text-primary-dark no-underline inline-block hover:text-secondary"
                  href="#services"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  className="text-xs text-primary-dark no-underline inline-block hover:text-secondary"
                  href="#networks"
                >
                  Networks
                </a>
              </li>
             {/* <li>
                <a
                  className="text-xs text-primary-dark no-underline inline-block hover:text-secondary"
                  href="#portfolio"
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a
                  className="text-xs text-primary-dark no-underline inline-block hover:text-secondary"
                  href="#faq"
                >
                  FAQs
                </a>
  </li> */}
            </ul>
          </div>
         {/* <div className="col w-6/12 lg:w-3/12 md:w-2/12">
            <div className="text-sm font-bold md:font-medium mt-6">Contacts</div>
            <p className="mt-4 text-xs text-primary-dark no-underline inline-block leading-5">
              <span className="block"> One Ferry Building, Suite 255.</span>
              <span className="block"> San Francisco, CA 94111</span>
              <span className="block text-base">+1 (415) 677-5340</span>
            </p>
</div> */}
          <div className="col w-full lg:w-3/12 md:w-4/12">
            <div className="text-sm font-bold md:font-medium mt-6">Subscribe to our newsletter</div>

            <form action="#" onSubmit={onSubmit}>
              <input required
                type="email"
                className="form-control text-sm mt-2"
                name="email"
                placeholder="hello@qubelabs.io"
              />
              <button className="btn is-primary mt-2" type='submit'>Subscribe</button>
            </form>
            {/* <button className="btn is-primary mt-2">Subscribe</button> */}
          </div>
        </div>
      {/*  <div className="mt-12">
          <p className="text-xs font-normal">Website designed by ©️ 2020—2021, jararmalik </p>
</div> */}
      </div>
    </footer>
  );
}